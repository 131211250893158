import { initializeIcons } from "@fluentui/react";
import { ConfigProvider } from "antd";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import "./App.css";
import APP_CONFIG from "./configs/environment";
import AuthLayout from "./layout/AuthLayout";
import QALayout from "./layout/QALayout";
import UnAuthLayout from "./layout/UnAuthLayout";
import ImageVerificationDetailPage from "./pages/ImageVerificationDetailPage/ImageVerificationDetailPage";
import ImageVerificationPage from "./pages/ImageVerificationPage/ImageVerificationPage";
import LogoQualityCheckPage from "./pages/LivePlatformDetailPage/LivePlatformDetailPage";
import LivePlatformPage from "./pages/LivePlatformPage/LivePlatformPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import MainChatPlatformPage from "./pages/MainChatPlatformPage/MainChatPlatformPage";
import store from "./redux/Store";
import colors from "./style.module.scss";
import TenantsPage from "./pages/TenantsPage/TenantsPage";
import AccessDenied from "./components/Template/AccessDenied/AccessDenied";
import NoAccessPage from "./pages/NoAccessedPage/NoAccessPage";
// import TenantsPage from "../pages/TenantsPage/TenantsPage"; // Added this line to import the TenantsPage component

Amplify.configure(APP_CONFIG.amplify_config);
initializeIcons();
function App() {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: { colorPrimary: colors.colorPrimary },
        }}>
        <Routes>
          <Route
            path="/"
            element={<UnAuthLayout />}>
            {/* Todo: Add login page here */}
            <Route
              path=""
              element={<LoginPage />}
            />
            <Route
              path="no-access"
              element={<NoAccessPage />}
            />
            {/* <Route
              path="forgot-password"
              element={<ForgotPasswordPage />}
            />
            <Route
              path="forgot-password-submit"
              element={<ForgotPasswordSubmitPage />}
            /> */}
          </Route>

          <Route
            path="live"
            element={<AuthLayout />}>
            <Route
              path=""
              element={<LivePlatformPage />}
            />
            <Route
              path="detail"
              element={<LogoQualityCheckPage />}>
              <Route
                path=":brandId"
                element={<LogoQualityCheckPage />}
              />
            </Route>

            {/* <Route
          path="live"
          element={<LiveProductionPage />}
        /> */}
          </Route>
          <Route
            path="/image-verification"
            element={<AuthLayout />}>
            <Route
              path=""
              element={<ImageVerificationPage />}
            />
            <Route
              path="detail"
              element={<ImageVerificationDetailPage />}>
              <Route
                path=":batchId/:brandId"
                element={<ImageVerificationDetailPage />}
              />
            </Route>
          </Route>

          <Route
            path="/settings"
            element={<AuthLayout />}>
            <Route
              path=""
              element={<TenantsPage />}
            />
          </Route>

          <Route
            path="/access-denied"
            element={<AuthLayout />}>
            <Route
              path=""
              element={<AccessDenied />}
            />
          </Route>

          <Route
            path="qa"
            element={<QALayout />}>
            <Route
              path=""
              element={<MainChatPlatformPage />}
            />
          </Route>
        </Routes>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
