import React, { useEffect, useLayoutEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/LayoutComponents/SidebarComponent/Sidebar/Sidebar";
import Footer from "../components/LayoutComponents/footer/Footer";
import AuthNav from "../components/LayoutComponents/nav/AuthNav";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../redux/Store";
import { useAppDispatch } from "../redux/useAppDispatch";
import { getSessionData, isUserLoggedIn } from "../services/auth/AuthAPI";
import { setCognitoGroups } from "../redux/LogoProduction/LogoProductionAction";

function AuthLayout(props: any) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // useLayoutEffect(() => {
  //   const checkUserLogin = async () => {
  //     // setLoading(true);
  //     const status = await isUserLoggedIn();
  //     const user = await getSessionData();
  //     console.log(user);
  //     if (user) {
  //       const fetchCognitoGroups = user.getIdToken().payload["cognito:groups"];
  //       // Dispatch the Cognito groups to the Redux store
  //       dispatch(setCognitoGroups(fetchCognitoGroups));
  //     } else {
  //       setIsAuthenticated(false);
  //     }

  //     setIsAuthenticated(status);
  //     setLoading(false);
  //   };
  //   checkUserLogin();
  // }, []);

  useLayoutEffect(() => {
    const checkUserLogin = async () => {
      try {
        const status = await isUserLoggedIn(); //Check if user is logged in
        if (status) {
          const session = await getSessionData(); // Fetch and store the latest token
          if (session) {
            const fetchCognitoGroups = await session.getIdToken().payload["cognito:groups"];
            dispatch(setCognitoGroups(fetchCognitoGroups));
            console.log(fetchCognitoGroups);
            if (fetchCognitoGroups.length === 0) {
              navigate("/no-access");
            }
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Auth error:", error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkUserLogin();
  }, []);

  if (loading) {
    return (
      <div
        className="app"
        style={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Spin size="large" />
      </div>
    );
  }

  return isAuthenticated ? (
    <div className="app">
      <AuthNav />
      <Sidebar />
      <Outlet />
      <Footer />
    </div>
  ) : (
    <Navigate to="/no-access" />
  );
}

export default AuthLayout;
