import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../redux/useAppDispatch";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Spin,
  TimePicker,
} from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import {
  convertDateToDayJS,
  convertDayJSToFullDate,
} from "../../utils/convertDateToDayJS";
import {
  saveTenant,
  setIsEditedForTenantModal,
} from "../../redux/Tenants/TenantsAction";
import dayjs from "dayjs";

interface TeanantsModalProps {
  close: () => void;
  openState: boolean;
  open: () => void;
}

function TenantsModal(props: TeanantsModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  //Getting each tenant Details
  const tenantDetails = useSelector(
    (state: RootState) => state.TenantsState.tenantState.data
  );

  const Edited = useSelector(
    (state: RootState) => state.TenantsState.tenantState.isEdited
  );

  // Loading state from redux slice
  const loading = useSelector(
    (state: RootState) => state.TenantsState.tenantState.loading
  );

  //Populating the Form
  useEffect(() => {
    if (tenantDetails) {
      const newParseOnBoardDate =
        tenantDetails.onboardDate && dayjs(tenantDetails.onboardDate).isValid()
          ? convertDateToDayJS(tenantDetails.onboardDate)
          : undefined; // Set to undefined for empty field
      const newParseOffBoardDate =
        tenantDetails.offboardDate &&
        dayjs(tenantDetails.offboardDate).isValid()
          ? convertDateToDayJS(tenantDetails.offboardDate)
          : undefined; // Set to undefined for empty field

      form.setFieldsValue({
        onboardDate: newParseOnBoardDate,
        onboardTime: newParseOnBoardDate,
        offboardDate: newParseOffBoardDate,
        offboardTime: newParseOffBoardDate,
        isActive: tenantDetails.isActive,
        maxBrandsAddIn: tenantDetails.maxItemsToSearchViaAddin,
        maxBrandsApi: tenantDetails.maxItemsToSearchViaApi,
        isLongSessionAllowed: tenantDetails.hasLongAuthSession,
        description: tenantDetails.description,
      });
    } else {
      form.resetFields();
      console.warn("tenantDetails is undefined or null");
    }
  }, [tenantDetails, form]);

  //Modal Close Reseting the Fields
  const handleModalClose = () => {
    props.close();
    form.resetFields();
    dispatch(setIsEditedForTenantModal(false));
  };

  const TenantsModalContent = useMemo(() => {
    const onFinish = (value: any) => {
      props.close();
      form.resetFields();

      // Handling Onboard Date and Time
      const updatedOnboardDate = value.onboardDate
        ? value.onboardDate.format("YYYY-MM-DD")
        : null;
      const updatedOnboardTime = value.onboardTime
        ? value.onboardTime.format("HH:mm")
        : null;

      const onboardDateTime =
        updatedOnboardDate && updatedOnboardTime
          ? convertDayJSToFullDate(
              dayjs(
                `${updatedOnboardDate} ${updatedOnboardTime}`,
                "YYYY-MM-DD HH:mm"
              )
            )
          : null;

      // Handling Offboard Date and Time
      const updatedOffboardDate = value.offboardDate
        ? value.offboardDate.format("YYYY-MM-DD")
        : null;
      const updatedOffboardTime = value.offboardTime
        ? value.offboardTime.format("HH:mm")
        : null;

      const offboardDateTime =
        updatedOffboardDate && updatedOffboardTime
          ? convertDayJSToFullDate(
              dayjs(
                `${updatedOffboardDate} ${updatedOffboardTime}`,
                "YYYY-MM-DD HH:mm"
              )
            )
          : null;

      // Prepare details object to be saved
      const details = {
        id: tenantDetails.id,
        displayName: tenantDetails.displayName,
        description: value.description,
        isActive: value.isActive,
        hasLongAuthSession: value.isLongSessionAllowed,
        maxItemsToSearchViaApi: value.maxBrandsApi,
        maxItemsToSearchViaAddin: value.maxBrandsAddIn,
        onboardDate: onboardDateTime,
        offboardDate: offboardDateTime,
      };

      dispatch(
        saveTenant({
          tenantDetail: details,
        })
      );
      dispatch(setIsEditedForTenantModal(false));
    };

    return (
      <Spin spinning={loading} className=" overflow-hidden">
        <div className="d-flex flex-column gap-4">
          <h4>{!loading && tenantDetails?.displayName} - Details</h4>

          <Form
            form={form}
            colon={false}
            name="tenantForm"
            layout="horizontal"
            labelCol={{ span: 8 }} // Consistent label width (out of 24)
            wrapperCol={{ span: 16 }} // Consistent wrapper width (out of 24)
            onFinish={onFinish}
            onValuesChange={() => {
              dispatch(setIsEditedForTenantModal(true));
            }}
          >
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  labelAlign="left"
                  style={{ marginLeft: "8px" }}
                  label={<span>Onboard Date (GMT)</span>}
                  name="onboardDate"
                  labelCol={{ span: 10 }} // Adjust label width
                  wrapperCol={{ span: 14 }} // Adjust wrapper width
                  required
                >
                  <Row gutter={4}>
                    <Col span={14}>
                      <Form.Item
                        name="onboardDate"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: "Onboard date is required",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{
                            width: "100%",
                            marginBottom: 0,
                          }}
                          placeholder="Date"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="onboardTime"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: "Onboard time is required",
                          },
                        ]}
                      >
                        <TimePicker
                          format="HH:mm"
                          style={{ width: "100%", marginBottom: 0 }}
                          placeholder="Time"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span>Offboard Date (GMT)</span>}
                  name="offboardDate"
                  labelCol={{ span: 10 }} // Adjust label width
                  wrapperCol={{ span: 14 }} // Adjust wrapper width
             
                  rules={[
                    {
                      validator: async (_, value) => {
                        const offboardTime = form.getFieldValue("offboardTime");
                        const onboardDate = form.getFieldValue("onboardDate");
                  
                        if (offboardTime && !value) {
                          return Promise.reject("Offboard Date is required when time is selected.");
                        }
                  
                        if (value) {
                          if (!offboardTime) {
                            return Promise.reject("Offboard Time is required.");
                          }
                  
                          if (onboardDate && value.isBefore(onboardDate, "day")) {
                            return Promise.reject("Offboard Date must be after Onboard Date.");
                          }
                        }
                  
                        return Promise.resolve(); // No errors
                      },
                    },
                  ]}
                  
                >
                  <Row gutter={4}>
                    <Col span={14}>
                      <Form.Item name="offboardDate">
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder="Date"
                          // dropdownClassName="custom-date-picker"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item name="offboardTime" noStyle>
                        <TimePicker
                          format="HH:mm"
                          style={{ width: "100%" }}
                          placeholder="Time"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ display: "block", textAlign: "left" }}>
                      Max items to search <br />
                      (Via Add-in)
                    </span>
                  }
                  name="maxBrandsAddIn"
                  labelCol={{ span: 10 }} // Adjust label width
                  wrapperCol={{ span: 14 }} // Adjust wrapper width
                  rules={[
                    {
                      required: true,
                      message: "Max-Items must be numeric.",
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    style={{ width: "100%", paddingBottom: "4px" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ display: "block", textAlign: "left" }}>
                      Max items to search <br />
                      (Via API)
                    </span>
                  }
                  name="maxBrandsApi"
                  labelCol={{ span: 10 }} // Adjust label width
                  wrapperCol={{ span: 14 }} // Adjust wrapper width
                  rules={[
                    {
                      required: true,
                      message: "Max-Items must be numeric.",
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    style={{ width: "100%", paddingBottom: "4px" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ marginLeft: "12px" }}>Is Active</span>}
                  name="isActive"
                  valuePropName="checked"
                  labelCol={{ span: 10 }} // Adjust label width
                  wrapperCol={{ span: 10 }} // Adjust wrapper width
                  labelAlign="left"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ marginLeft: "12px" }}>
                      Is long sign-in allowed
                    </span>
                  }
                  name="isLongSessionAllowed"
                  valuePropName="checked"
                  labelCol={{ span: 10 }} // Adjust label width
                  wrapperCol={{ span: 10 }} // Adjust wrapper width
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label={<span style={{ marginLeft: "12px" }}>Description</span>}
              name="description"
              labelCol={{ span: 5 }} // Adjust label width
              wrapperCol={{ span: 19 }} // Adjust wrapper width
              rules={[
                {
                  max: 100,
                  message: "Description cannot exceed 100 characters",
                },
              ]}
              labelAlign="left"
            >
              <Input.TextArea rows={2} />
            </Form.Item>

            <div className="d-flex justify-content-end gap-3">
              <Button
                type="primary"
                htmlType="submit"
                disabled={Edited ? false : true}
              >
                Save
              </Button>
              <Button htmlType="reset" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Spin>
    );
  }, [form, tenantDetails, loading, Edited]);

  return (
    <Modal
      centered
      zIndex={1500}
      footer={[]}
      width={800}
      onCancel={handleModalClose}
      open={props.openState}
    >
      {TenantsModalContent}
    </Modal>
  );
}

export default TenantsModal;
