import React, { useEffect, useState } from "react";
import TableComponent from "../../components/TableComponent/TableComponent";
import LivePlatformTableColumns from "../../components/LivePlatformComponent/LivePlatformTableColumns";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { useAppDispatch } from "../../redux/useAppDispatch";
import Search from "antd/es/input/Search";
import "./LivePlatfromPage.css";
import AutoRefreshCheckbox from "../../components/LivePlatformComponent/AutoRefreshCheckbox";
import LoadingPage from "../templates/LoadingPage/LoadingPage";
import { resetTable, updateRequestBody, fetchLogos, searchDocuments, stopAutoRefresh, fetchPaginateLogos, createBrandOpenModal, createBrandCloseModal } from "../../redux/LivePlatform/LivePlatformAction";
import { Button, Switch } from "antd";
import CreateBrandModal from "../../components/LivePlatformDetailPageComponent/ModalComponent/CreateBrandModal";
export interface LivePlatformPageProps {}

function LivePlatformPage(props: LivePlatformPageProps) {
  const livePlatformPageState = useSelector((state: RootState) => state.livePlatformState);

  const [searchValue, setSearchValue] = useState(livePlatformPageState.currentRequestBody.keyword);
  const [includeArchiveBrands, setIncludeArchiveBrands] = useState(livePlatformPageState.currentRequestBody.includeArchiveBrands);
  const brandDetailState = useSelector((state: RootState) => state.livePlatformState.brandDetailState);
  const dispatch = useAppDispatch();
  const handleTableSorting = (sorter: any) => {
    const isAscend = (order: string) => {
      if (order === "ascend") {
        return true;
      }
      return false;
    };
    const newSortingRequestBody = { ...livePlatformPageState.currentRequestBody, sortColumn: sorter.field, isAsc: isAscend(sorter.order) };
    dispatch(resetTable());
    dispatch(updateRequestBody(newSortingRequestBody));
    dispatch(fetchLogos({}));
  };
  const onSearch = (value: string) => {
    dispatch(
      searchDocuments({
        searchKeyword: value,
        includeArchiveBrands:includeArchiveBrands,
      })
    );
  };
  const inCludeArchiveBrand = (value: boolean) => {
    setIncludeArchiveBrands(value);
    dispatch(
      searchDocuments({
        searchKeyword: searchValue,
        includeArchiveBrands:value,
        
      })
    );
  };

  useEffect(() => {
    dispatch(fetchLogos({}));
    return () => {
      dispatch(stopAutoRefresh());
      dispatch(resetTable());
    };
  }, []);
  // if (livePlatformPageState.loading && livePlatformPageState.data.length === 0) {
  //   return <LoadingPage />;
  // }

  function handleOnClickCreateBrand(){
    dispatch(createBrandOpenModal());
    // close={() => dispatch(closeEditStockSymbolModal())}
    // openState={brandDetailState.openEditStockSymbolModalState}
  }
  return (
    <div className="body-container gap-3">
      <CreateBrandModal
        open={() => dispatch(createBrandOpenModal())}
        close={() => dispatch(createBrandCloseModal())}
        openState={brandDetailState.openCreateBrandModalState}
      />
      <h2>Brands</h2>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-between">
          <Search
            type="text"
            size="large"
            value={searchValue}
            onSearch={(value) => onSearch(value)}
            allowClear
            placeholder="Search by Name, Website, Country, Stock Symbol, Tag, ISIN or Status"
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: 580 }}
            //style to make the content in the center horizontally of the parent position relative
          />
            <div className="include-archive-brand-checkbox h-100  ">
            <Switch style={{ margin:"10px",fontSize:"15px",boxShadow:"none"}} size="default" defaultChecked={livePlatformPageState.currentRequestBody.includeArchiveBrands} onChange={(value:boolean)=>inCludeArchiveBrand(value)}/>

            <span>Include Archive Brands</span>
          </div>
        </div>
        <div  className="d-flex justify-content-between h-100 ">
        <Button  style={{ marginTop:"6px",fontSize:"15px",boxShadow:"none"}} type="primary" onClick={()=>{handleOnClickCreateBrand()}}>Create Brand</Button>
        <AutoRefreshCheckbox />
        </div>
       
      </div>

      <TableComponent
        columns={LivePlatformTableColumns}
        dataSource={livePlatformPageState.data}
        pagination={livePlatformPageState.pagination}
        handleTableSorting={handleTableSorting}
        fetchPaginatedData={() => fetchPaginateLogos({})}
        loading={livePlatformPageState.loading}
        tableKey="livePlatform"
      />
    </div>
  );
}

export default LivePlatformPage;


