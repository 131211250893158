import { createSlice } from "@reduxjs/toolkit";
import { defaultTenantsBody, DefaultTenantsBodyType } from "../../constant/logoProductionType";
import { updateTenantsRequestBody, resetTenantsTable, setTenantsAutoRefresh, switchTenantsAutoFresh, stopTenantsAutoRefresh, fetchTenants, fetchPaginateTenants, fetchRefreshTenants, tenantsCloseModal, tenantsOpenModal, fetchTenantsDetail, setIsEditedForTenantModal, saveTenant } from "./TenantsAction";

export interface PaginationType {
  page: number;
  pageSize: number;
  hasMore: boolean;
  loading: boolean;
}

interface AutoRefreshStateType {
  autoRefresh: boolean;
  currentIntervalId: NodeJS.Timer | undefined;
  error: string | undefined;
  loading: boolean;
}

export interface Tenant {
  id: number;
  name: string;
  description: string;
  onboardDate: string;
  offboardDate: string;
  systemName: string;
  isLongSessionAllowed: boolean;
  maxBrandsAddIn: number;
  maxBrandsApi: number;
  isActive: boolean;
}

export interface TenantState {
  data: any;
  isEdited: boolean;
  id: number;
  loading: boolean;
  name: string;
  description: string;
  onboardDate: string;
  offboardDate: string;
  systemName: string;
  isLongSessionAllowed: boolean;
  maxBrandsAddIn: number;
  maxBrandsApi: number;
  isActive: boolean;

  //Modal
  tenantsModalState: boolean;
}

export interface TenantsStateType {
  data: any;
  loading: boolean;
  error: string | undefined;
  currentRequestBody: DefaultTenantsBodyType;
  pagination: PaginationType;
  autoRefreshState: AutoRefreshStateType;
  tenantState: TenantState;
}

const tenantsInitialState: TenantsStateType = {
  data: [],
  loading: false,
  error: undefined,
  currentRequestBody: defaultTenantsBody,
  pagination: {
    page: 2,
    pageSize: 30,
    hasMore: true,
    loading: false,
  },
  autoRefreshState: {
    autoRefresh: true,
    currentIntervalId: undefined,
    error: "",
    loading: false,
  },
  tenantState: {
    loading: false,
    data: [],
    isEdited: false,
    id: 0,
    name: "",
    description: "",
    onboardDate: "",
    offboardDate: "",
    systemName: "",
    isLongSessionAllowed: false,
    maxBrandsAddIn: 0,
    maxBrandsApi: 0,
    isActive: false,

    //Modal
    tenantsModalState: false,
  },
};

const TenantSlice = createSlice({
  initialState: tenantsInitialState,
  name: "logoProduction",
  reducers: {},
  extraReducers(builder) {
    builder
      //Fetch tenant dat from API
      .addCase(fetchTenants.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchTenants.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchTenants.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      //Infinite Scrolling
      .addCase(fetchPaginateTenants.pending, (state, action) => {
        state.pagination.loading = true;
      })
      .addCase(fetchPaginateTenants.fulfilled, (state, action) => {
        state.pagination.page = state.pagination.page + 1;
        state.data = state.data.concat(action.payload.data);
        state.pagination.hasMore = action.payload.data.length === state.pagination.pageSize;
        state.pagination.loading = false;
      })
      .addCase(fetchPaginateTenants.rejected, (state, action) => {
        state.error = action.error.message;
        state.pagination.loading = false;
      })

      //Search tenants
      .addCase(updateTenantsRequestBody, (state, action) => {
        state.currentRequestBody = action.payload;
      })
      .addCase(resetTenantsTable, (state, action) => {
        return {
          ...tenantsInitialState,
          data: state.data,
        };
      })

      .addCase(fetchRefreshTenants.pending, (state, action) => {
        state.autoRefreshState.loading = true;
      })
      .addCase(fetchRefreshTenants.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.autoRefreshState.loading = false;
      })
      .addCase(fetchRefreshTenants.rejected, (state, action) => {
        state.autoRefreshState.error = action.error.message;
        state.autoRefreshState.loading = false;
      })

      //Auto Refresh
      .addCase(setTenantsAutoRefresh, (state, action) => {
        state.autoRefreshState.currentIntervalId = action.payload.intervalId;
      })
      .addCase(switchTenantsAutoFresh, (state, action) => {
        state.autoRefreshState.autoRefresh = action.payload.autoRefresh;
      })
      .addCase(stopTenantsAutoRefresh, (state, action) => {
        clearInterval(state.autoRefreshState.currentIntervalId);
      })

      //Modal Tenants
      .addCase(tenantsOpenModal, (state, action) => {
        state.tenantState.tenantsModalState = true;
      })
      .addCase(tenantsCloseModal, (state, action) => {
        state.tenantState.tenantsModalState = false;
      })

      //Fetching each tenantDeatils
      .addCase(fetchTenantsDetail.pending, (state, action) => {
        state.tenantState.loading = true;
      })
      .addCase(fetchTenantsDetail.fulfilled, (state, action) => {
        state.tenantState.data = action.payload.tenantDetail;
        state.tenantState.loading = false;
      })

      .addCase(fetchTenantsDetail.rejected, (state, action) => {
        state.error = action.error.message;
        state.tenantState.loading = false;
      })

      //Save Enable and Disable

      .addCase(setIsEditedForTenantModal, (state, action) => {
        state.tenantState.isEdited = action.payload;
      })

      //Save tenants and Edit
      .addCase(saveTenant.pending, (state, action) => {
        state.tenantState.loading = true;
      })
      .addCase(saveTenant.fulfilled, (state, action) => {
        state.tenantState.data = {
          ...state.tenantState.data,
          ...action.payload,
        };
        state.tenantState.loading = false;
      })
      .addCase(saveTenant.rejected, (state, action) => {
        state.error = action.error.message;
        state.tenantState.loading = false;
      });
  },
});
export default TenantSlice.reducer;
