import { createSlice } from "@reduxjs/toolkit";
import { BrandDetailResponseType, DefaultAuditRequestBodyType, DefaultRequestBodyType, defaultAuditRequestBody, defaultRequestBody } from "../../constant/logoProductionType";
import { fetchLogos, fetchPaginateLogos, updateRequestBody, resetTable, fetchRefreshDocuments, fetchBrandDetail, saveBrandDetail, saveImageDetail, deleteImage, acceptImage, setCurrentImageIndex, setAutoRefresh, switchAutoFresh, stopAutoRefresh, addSymbol, updateStockSymbol, editSymbol, deleteSymbol, setStockSymbols, cleanBrandDetail, setIsEdited, openDeleteStockSymbolModal, closeDeleteStockSymbolModal, openEditStockSymbolModal, closeEditStockSymbolModal, openAddStockSymbolModal, closeAddStockSymbolModal, openConfirmDeleteModal, closeConfirmDeleteModal, openUnsavedChangesModal, closeUnsavedChangesModal, resetAuditEntryTable, updateAuditRequestBody, fetchAuditEntriesDetails, fetchAuditPaginateEntries, saveIndustryDetail, openDeleteBrandModal, closeDeleteBrandModal, openArchiveBrandModal, closeArchiveBrandModal, openUnArchiveBrandModal, closeUnArchiveBrandModal, createBrandOpenModal, createBrandCloseModal } from "./LivePlatformAction";

export interface PaginationType {
  page: number;
  pageSize: number;
  hasMore: boolean;
  loading: boolean;
}
interface AutoRefreshStateType {
  autoRefresh: boolean;
  currentIntervalId: NodeJS.Timer | undefined;
  error: string | undefined;
  loading: boolean;
}
interface BrandDetailStateType {
  loading: boolean;
  saveLoading: boolean;
  error: string | undefined;
  currentStockSymbol: {
    symbol: string;
    exchangeName: string;
    id: number;
    index: number;
  };
  stockSymbolSelectedToDelete: {
    symbol: string;
    exchangeName: string;
    id: number;
  };
  data: BrandDetailResponseType;
  dropDownData: {
    country: { value: string; label: string }[];
    stockExchange: { value: string; label: string }[];
    imageMetaData: any;
    sectorList: any;
  };
  isEdited: boolean;
  openStockSymbolModalState: boolean;
  openCreateBrandModalState: boolean;
  openEditStockSymbolModalState: boolean;
  openDeleteStockSymbolModalState: boolean;
  openConfirmDeleteModalState: boolean;
  openUnsavedChangesModalState: boolean;
  openGoLiveConfirmModalState: boolean;
  currentImageIndex: number;
  imageViewerLoading: boolean;
  openConfirmBrandDeleteModalState: boolean;
  openConfirmArchiveBrandModalState: boolean;
  openConfirmUnArchiveBrandModalState: boolean;
}
interface AuditEntriesStateType {
  data: any;
  loading: boolean;
  error: string | undefined;
  currentRequestBody: DefaultAuditRequestBodyType;
  pagination: PaginationType;
  id: number;
  createdAt: string;
  createdBy: string;
  shortMessage: string;
  changes: string;
}

interface LivePlatformStateType {
  data: any;
  loading: boolean;
  error: string | undefined;
  currentRequestBody: DefaultRequestBodyType;
  pagination: PaginationType;
  autoRefreshState: AutoRefreshStateType;
  auditEntriesState: AuditEntriesStateType;
  brandDetailState: BrandDetailStateType;
}

const livePlatFormInitialState: LivePlatformStateType = {
  data: [],
  loading: false,
  error: undefined,
  currentRequestBody: defaultRequestBody,
  pagination: {
    page: 2,
    pageSize: 30,
    hasMore: true,
    loading: false,
  },
  autoRefreshState: {
    autoRefresh: true,
    currentIntervalId: undefined,
    error: "",
    loading: false,
  },
  auditEntriesState: {
    data: [],
    loading: false,
    error: "",
    currentRequestBody: defaultAuditRequestBody,
    pagination: {
      page: 2,
      pageSize: 30,
      hasMore: true,
      loading: false,
    },
    id: 0,
    createdAt: "",
    createdBy: "",
    shortMessage: "",
    changes: "",
  },
  brandDetailState: {
    loading: false,
    error: "",
    currentStockSymbol: { symbol: "", exchangeName: "", id: 0, index: 0 },
    stockSymbolSelectedToDelete: { symbol: "", exchangeName: "", id: 0 },
    data: {
      id: 0,
      name: "",
      domain: "",
      stockSymbols: [],
      isin: "",
      isArchived: false,
      isDummyDomain: false,
      // country: "",
      status: "",
      batchId: 0,

      description: "",

      images: [
        {
          id: 0,
          type: "",
          src: "",
          opacity: "",
          format: "",
          size: 0,
          height: 0,
          width: 0,
          color: "",
          theme: "",
          status: "",
          comments: "",
          fileName: "",
          isArchived: false,
        },
      ],
      tags: [],
      sectors: [],
      industries: [],
      brandLocation: {
        id: 4,
        brandId: 314436,
        city: "",
        state: "",
        country: "",
        countryCode: "",
        subRegion: "",
        region: "",
      },
    },
    dropDownData: {
      country: [],
      stockExchange: [],
      imageMetaData: {},
      sectorList: [],
    },
    isEdited: false,
    saveLoading: false,
    openStockSymbolModalState: false,
    openConfirmDeleteModalState: false,
    openConfirmBrandDeleteModalState: false,
    openConfirmArchiveBrandModalState: false,
    openConfirmUnArchiveBrandModalState: false,
    openCreateBrandModalState: false,
    openEditStockSymbolModalState: false,
    openDeleteStockSymbolModalState: false,
    openUnsavedChangesModalState: false,
    openGoLiveConfirmModalState: false,
    currentImageIndex: 0,
    imageViewerLoading: false,
  },
};

const livePlatformSlice = createSlice({
  initialState: livePlatFormInitialState,
  name: "logoProduction",
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLogos.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchLogos.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchLogos.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchPaginateLogos.pending, (state, action) => {
        state.pagination.loading = true;
      })
      .addCase(fetchPaginateLogos.fulfilled, (state, action) => {
        state.pagination.page = state.pagination.page + 1;
        state.data = state.data.concat(action.payload.data);
        state.pagination.hasMore = action.payload.data.length === state.pagination.pageSize;
        state.pagination.loading = false;
      })
      .addCase(fetchPaginateLogos.rejected, (state, action) => {
        state.error = action.error.message;
        state.pagination.loading = false;
      })
      .addCase(updateRequestBody, (state, action) => {
        state.currentRequestBody = action.payload;
      })
      .addCase(resetTable, (state, action) => {
        return {
          ...livePlatFormInitialState,
          data: state.data,
        };
      })
      .addCase(fetchRefreshDocuments.pending, (state, action) => {
        state.autoRefreshState.loading = true;
      })
      .addCase(fetchRefreshDocuments.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.autoRefreshState.loading = false;
      })
      .addCase(fetchRefreshDocuments.rejected, (state, action) => {
        state.autoRefreshState.error = action.error.message;
        state.autoRefreshState.loading = false;
      })
      .addCase(fetchBrandDetail.pending, (state, action) => {
        state.brandDetailState.loading = true;
      })
      .addCase(fetchBrandDetail.fulfilled, (state, action) => {
        state.brandDetailState.data = action.payload.brandDetail.data;
        state.brandDetailState.dropDownData = action.payload.dropdownData;
        state.brandDetailState.loading = false;
      })

      .addCase(fetchBrandDetail.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.loading = false;
      })
      .addCase(saveBrandDetail.pending, (state, action) => {
        state.brandDetailState.saveLoading = true;
      })
      .addCase(saveBrandDetail.fulfilled, (state, action) => {
        state.brandDetailState.data = {
          ...state.brandDetailState.data,
          ...action.payload,
          images: state.brandDetailState.data.images,
          sectors: state.brandDetailState.data.sectors,
          industries: state.brandDetailState.data.industries,
        };
        state.brandDetailState.saveLoading = false;
      })
      .addCase(saveBrandDetail.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.saveLoading = false;
      })
      //industry details
      .addCase(saveIndustryDetail.pending, (state, action) => {
        state.brandDetailState.saveLoading = true;
      })
      // .addCase(saveIndustryDetail.fulfilled, (state, action) => {
      //   state.brandDetailState.data = {
      //     ...action.payload,
      //     images: state.brandDetailState.data.images,
      //   };
      //   state.brandDetailState.saveLoading = false;
      // })
      .addCase(saveIndustryDetail.fulfilled, (state, action) => {
        state.brandDetailState.data = {
          ...state.brandDetailState.data, // Retain all existing properties
          ...action.payload, // Overwrite with any new data from the action payload
          images: state.brandDetailState.data.images, // Ensure images are not overwritten
        };
        state.brandDetailState.saveLoading = false;
      })

      .addCase(saveIndustryDetail.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.saveLoading = false;
      })
      .addCase(saveImageDetail.pending, (state, action) => {
        state.brandDetailState.saveLoading = true;
      })
      .addCase(saveImageDetail.fulfilled, (state, action) => {
        state.brandDetailState.data.images[state.brandDetailState.currentImageIndex] = {
          ...action.payload,
          src: state.brandDetailState.data.images[state.brandDetailState.currentImageIndex].src,
          status: state.brandDetailState.data.images[state.brandDetailState.currentImageIndex].status,
        };
        state.brandDetailState.saveLoading = false;
      })
      .addCase(saveImageDetail.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.saveLoading = false;
      })
      .addCase(deleteImage.pending, (state, action) => {
        state.brandDetailState.imageViewerLoading = true;
      })
      .addCase(deleteImage.fulfilled, (state, action) => {
        // console.log(state.brandDetailState.data.images.length, "length 1");
        // console.log(state.brandDetailState.currentImageIndex, "current index 1");
        // console.log(state.brandDetailState.data.images, "image 1");
        // console.log(state.brandDetailState.data.images.length, "length 2");
        // console.log(state.brandDetailState.currentImageIndex, "current index 2");
        state.brandDetailState.data.images.splice(state.brandDetailState.currentImageIndex, 1);
        // state.brandDetailState.data.images = state.brandDetailState.data.images.splice(state.brandDetailState.data.images.length - 1, 1);

        // console.log(state.brandDetailState.data.images, "image 2");
        // console.log(state.brandDetailState.data.images.length === state.brandDetailState.currentImageIndex, "check this condtion");
        state.brandDetailState.currentImageIndex = state.brandDetailState.data.images.length === state.brandDetailState.currentImageIndex ? 0 : state.brandDetailState.currentImageIndex;
        state.brandDetailState.imageViewerLoading = false;
      })

      .addCase(deleteImage.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.imageViewerLoading = false;
      })
      .addCase(acceptImage.pending, (state, action) => {
        state.brandDetailState.imageViewerLoading = true;
      })
      .addCase(acceptImage.fulfilled, (state, action) => {
        state.brandDetailState.data.images[state.brandDetailState.currentImageIndex] = {
          ...state.brandDetailState.data.images[state.brandDetailState.currentImageIndex],
          status: "Live",
        };
        state.brandDetailState.currentImageIndex = state.brandDetailState.data.images.length === state.brandDetailState.currentImageIndex + 1 ? 0 : state.brandDetailState.currentImageIndex + 1;
        state.brandDetailState.imageViewerLoading = false;
      })
      .addCase(acceptImage.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.imageViewerLoading = false;
      })

      .addCase(setCurrentImageIndex, (state, action) => {
        state.brandDetailState.currentImageIndex = action.payload;
      })

      .addCase(setAutoRefresh, (state, action) => {
        state.autoRefreshState.currentIntervalId = action.payload.intervalId;
      })
      .addCase(switchAutoFresh, (state, action) => {
        state.autoRefreshState.autoRefresh = action.payload.autoRefresh;
      })
      .addCase(stopAutoRefresh, (state, action) => {
        clearInterval(state.autoRefreshState.currentIntervalId);
      })
      .addCase(addSymbol, (state, action) => {
        state.brandDetailState.data.stockSymbols.push(action.payload);
      })
      .addCase(updateStockSymbol, (state, action) => {
        state.brandDetailState.currentStockSymbol = action.payload;
      })
      .addCase(editSymbol, (state, action) => {
        state.brandDetailState.data.stockSymbols.forEach((element, index) => {
          if (index === state.brandDetailState.currentStockSymbol.index) {
            state.brandDetailState.data.stockSymbols[index] = {
              ...state.brandDetailState.data.stockSymbols[index],
              symbol: action.payload.symbol,
              exchangeName: action.payload.exchangeName,
            };
          }
        });
      })
      .addCase(deleteSymbol, (state, action) => {
        const updatedStockSymbols = state.brandDetailState.data.stockSymbols.filter((element, idx) => idx !== state.brandDetailState.currentStockSymbol.index);
        state.brandDetailState.data.stockSymbols = updatedStockSymbols;
      })
      .addCase(setStockSymbols, (state, action) => {
        state.brandDetailState.data.stockSymbols = action.payload;
      })

      .addCase(cleanBrandDetail, (state, action) => {
        state.brandDetailState = livePlatFormInitialState.brandDetailState;
      })
      .addCase(setIsEdited, (state, action) => {
        state.brandDetailState.isEdited = action.payload;
      })

      .addCase(openDeleteStockSymbolModal, (state, action) => {
        state.brandDetailState.openDeleteStockSymbolModalState = true;
      })
      .addCase(closeDeleteStockSymbolModal, (state, action) => {
        state.brandDetailState.openDeleteStockSymbolModalState = false;
      })
      .addCase(openEditStockSymbolModal, (state, action) => {
        state.brandDetailState.openEditStockSymbolModalState = true;
      })
      .addCase(closeEditStockSymbolModal, (state, action) => {
        state.brandDetailState.openEditStockSymbolModalState = false;
      })
      .addCase(openAddStockSymbolModal, (state, action) => {
        state.brandDetailState.openStockSymbolModalState = true;
      })
      .addCase(closeAddStockSymbolModal, (state, action) => {
        state.brandDetailState.openStockSymbolModalState = false;
      })
      .addCase(createBrandOpenModal, (state, action) => {
        state.brandDetailState.openCreateBrandModalState = true;
      })
      .addCase(createBrandCloseModal, (state, action) => {
        state.brandDetailState.openCreateBrandModalState = false;
      })
      .addCase(openConfirmDeleteModal, (state, action) => {
        state.brandDetailState.openConfirmDeleteModalState = true;
      })
      .addCase(closeConfirmDeleteModal, (state, action) => {
        state.brandDetailState.openConfirmDeleteModalState = false;
      })
      .addCase(openUnsavedChangesModal, (state, action) => {
        state.brandDetailState.openUnsavedChangesModalState = true;
      })
      .addCase(closeUnsavedChangesModal, (state, action) => {
        state.brandDetailState.openUnsavedChangesModalState = false;
      })

      .addCase(resetAuditEntryTable, (state, action) => {
        state.auditEntriesState = {
          ...livePlatFormInitialState.auditEntriesState,
          data: state.auditEntriesState.data,
        };
      })
      .addCase(updateAuditRequestBody, (state, action) => {
        state.auditEntriesState.currentRequestBody = action.payload;
      })
      .addCase(fetchAuditEntriesDetails.pending, (state, action) => {
        state.auditEntriesState.loading = true;
      })
      .addCase(fetchAuditEntriesDetails.fulfilled, (state, action) => {
        state.auditEntriesState.data = action.payload.results;
        // state.auditEntriesState.dropDownData = action.payload.dropdownData;
        state.auditEntriesState.loading = false;
      })

      .addCase(fetchAuditEntriesDetails.rejected, (state, action) => {
        state.auditEntriesState.error = action.error.message;
        state.auditEntriesState.loading = false;
      })
      .addCase(fetchAuditPaginateEntries.pending, (state, action) => {
        state.auditEntriesState.pagination.loading = true;
      })
      .addCase(fetchAuditPaginateEntries.fulfilled, (state, action) => {
        state.auditEntriesState.pagination.page = state.auditEntriesState.pagination.page + 1;
        // state.auditEntriesState.data = [...state.auditEntriesState.data, ...action.payload.results];
        state.auditEntriesState.data = state.auditEntriesState.data.concat(action.payload.data.results);
        state.auditEntriesState.pagination.hasMore = action.payload.length === state.auditEntriesState.pagination.pageSize;
        state.auditEntriesState.pagination.loading = false;
      })
      .addCase(fetchAuditPaginateEntries.rejected, (state, action) => {
        // state.auditEntriesState.error = action.error.message;
        state.auditEntriesState.pagination.loading = false;
      })
      .addCase(openDeleteBrandModal, (state, action) => {
        state.brandDetailState.openConfirmBrandDeleteModalState = true;
      })
      .addCase(closeDeleteBrandModal, (state, action) => {
        state.brandDetailState.openConfirmBrandDeleteModalState = false;
      })
      .addCase(openArchiveBrandModal, (state, action) => {
        state.brandDetailState.openConfirmArchiveBrandModalState = true;
      })
      .addCase(closeArchiveBrandModal, (state, action) => {
        state.brandDetailState.openConfirmArchiveBrandModalState = false;
      })
      .addCase(openUnArchiveBrandModal, (state, action) => {
        state.brandDetailState.openConfirmUnArchiveBrandModalState = true;
      })
      .addCase(closeUnArchiveBrandModal, (state, action) => {
        state.brandDetailState.openConfirmUnArchiveBrandModalState = false;
      });
  },
});
export default livePlatformSlice.reducer;
