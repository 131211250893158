import { useNavigate } from "react-router";
import "./AccessDenied.scss";
import { FontIcon } from "@fluentui/react";

const AccessDenied = () => {
  const navigate = useNavigate();
  return (
    <div className="ad-container">
      <div className="ad-header">
        <FontIcon
          iconName="Lock"
          className="icon lock ad-scale"
        />
        <h4 className="ad-title">Access Denied</h4>
      </div>
      <p className="ad-text">You do not have permission to access this page.</p>
      <button
        className="button ad-scale"
        onClick={() => navigate("/image-verification")}>
        Return
      </button>
    </div>
  );
};

export default AccessDenied;
